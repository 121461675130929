<template>
	<div>Work Order</div>
</template>

<script>
export default {
	name: "workOrder",
	mounted() {
		document.title = "Work Order | magardTELE";
	}
}
</script>